<template>
    <transition name="fade" mode="out-in">
        <asc-error v-if="notFound" key="notfound" :not-found="true" />

        <page-template v-else-if="!$fetchState.pending && page" key="content">
            <template v-slot:default>
                <asc-page-title>
                    {{ page.title }}
                </asc-page-title>

                <template v-for="block in page.content">
                    <section :key="block.key" class="spacing-bottom">
                        <asc-markdown
                            v-if="block.content"
                            :markdown="block.content"
                        />

                        <img
                            v-if="block.image"
                            :src="block.image"
                            :srcset="`
                                ${block.image} 680w,
                                ${block.image_medium} 480w,
                                ${block.image_mobile} 320w
                            `"
                            width="680"
                            loading="lazy"
                        >
                    </section>
                </template>
            </template>

            <template v-slot:bottom>
                <asc-section>
                    <template v-slot:title>
                        <h2>More information</h2>
                    </template>

                    <template v-slot:default>
                        <asc-page-nav />
                    </template>
                </asc-section>
            </template>
        </page-template>
    </transition>
</template>

<script>
import pageBySlug from '~/graphql/queries/page/pageBySlug.graphql';

import PageTemplate from '~/patterns/templates/page.vue';

import AscMarkdown from '~/patterns/atoms/markdown-renderer/markdown-renderer.vue';

import AscPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import AscPageNav from '~/patterns/molecules/page-nav/page-nav.vue';
import AscError from '~/patterns/organisms/error/error.vue';

import AscSection from '~/patterns/organisms/section/section.vue';

/**
 * Note: Even though this would normally be rendered inside <nuxt-child> from `pages/updates.vue`,
 * we've modified the routes in `nuxt.config.js` so it isn't so we can change what is rendered in the layout
 */
export default {

    components: {
        PageTemplate,
        AscError,
        AscSection,
        AscPageTitle,
        AscPageNav,
        AscMarkdown
    },
    scrollToTop: true,

    data() {
        return {
            page: null,
            flexibleSections: null,
            notFound: false
        };
    },

    async fetch() {
        try {
            const { data } = await this.$gql.executeQuery(pageBySlug, { slug: this.$route.params.slug });
            if (!data.pageBySlug) {
                this.notFound = true;

                if (process.server) {
                    this.$nuxt.context.res.statusCode = 404;
                }
            } else {
                this.page = data?.pageBySlug;
            }
        } catch (e) {
            this.notFound = true;
            if (process.server) {
                this.$nuxt.context.res.statusCode = 404;
            }
        }
    },

    head() {
        return this.$seo.getMetaData({
            title: `${this.page ? this.page.title : ''}`
        });
    }
};
</script>
